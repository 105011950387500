import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import { Context } from '../Context.js';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { formatDate } from '../tools.js';
import { Close } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, Button, Grid, Box, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web'
import { apiaddress } from "../const.js"

export default function Livepage(props) {
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [sidebarstatus, setSidebarstatus] = useContext(Context);
    const { keycloak, initialized } = useKeycloak();
    const [value, setValue] = useState(props.value);
    const [liveData, setLiveData] = useState([]);
    const [key, setKey] = useState();
    const [gridApi, setGridApi] = useState();
    const [calibration, setCalibration] = useState(0);
    const [loadedCalibration, setLoadedCalibration] = useState({});

    const useStyles = makeStyles((theme) => ({
        toolbar: {
            height: '60px',
            minHeight: "60px",
            minWidth: "500px",
            backgroundColor: theme.palette.primary.light,
        },
        maingrid: {
            height: '100vh',
            width: '100%',
            backgroundColor: '#f4f9fc'
        },
        datagrid: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }));
    const classes = useStyles();

    if (sidebarstatus != "list" && sidebarstatus != key && sidebarstatus != undefined) {
        setKey(sidebarstatus);
        updateData();
        updateCalibration();
    }

    function UseTimer() {
        useInterval(() => {
            updateData();
        }, 2000);
        return null;
    };

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    async function updateData() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/equipments/live`, { headers: { Authorization: keycloak.token }, params: { id: value[sidebarstatus].EquipmentID } })
            .then(response => {
                if (response.status === 200 && typeof response.data == "object" && Object.keys(response.data).length > 0) {
                    let data = prepareData(response.data);
                    if (data.length != liveData.length) {
                        setLiveData(data);
                    }
                    else if (gridApi != undefined) {
                        gridApi.forEachNode((rowNode) => {
                            const newData = data.find((val) => val.label === rowNode.data.label);
                            if (newData !== undefined) {
                                let newValue = newData.value;
                                let newDate = newData.date;
                                if (rowNode.data.value != newValue) {
                                    //liveData[i].oldValue = liveData[i].value;
                                    //liveData[i].value = newValue;
                                    rowNode.setDataValue("oldValue", rowNode.data.value);
                                    rowNode.setDataValue("value", newValue);
                                }
                                if (rowNode.data.date != newDate) {
                                    //liveData[i].date = newDate;
                                    rowNode.setDataValue("date", newDate);
                                }
                            }
                        });

                        /*for (let i = 0; i < liveData.length; i++) {
                            const rowNode = gridApi.getDisplayedRowAtIndex(i);
                            console.log(rowNode);
                            const newData = data.find((val) => val.label === liveData[i].label);
                            if (newData !== undefined) {
                                let newValue = newData.value;
                                let newDate = newData.date;
                                if (liveData[i].value != newValue) {
                                    liveData[i].oldValue = liveData[i].value;
                                    liveData[i].value = newValue;
                                    rowNode.setDataValue("oldValue", liveData[i].oldValue);
                                    rowNode.setDataValue("value", liveData[i].value);
                                }
                                if (liveData[i].date != newDate) {
                                    liveData[i].date = newDate;
                                    rowNode.setDataValue("date", liveData[i].date);
                                }
                            }
                        }
                        */
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        setMainwindow("login");
                    }
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            });
    }

    async function updateCalibration() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/calibrations`, { headers: { Authorization: keycloak.token } })
            .then(response => {
                if (response.status === 200 && typeof response.data === "object" && Object.keys(response.data).length > 0)
                    setLoadedCalibration(response.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            });
    }

    function prepareData(dataArray) {
        let newArray = [];
        const date = dataArray.Timestamp;
        newArray.push({ "label": "DIn 0", "value": dataArray.DIn_0, "date": date })
        newArray.push({ "label": "DIn 1", "value": dataArray.DIn_1, "date": date })
        newArray.push({ "label": "DIn 2", "value": dataArray.DIn_2, "date": date })
        newArray.push({ "label": "DIn 3", "value": dataArray.DIn_3, "date": date })
        newArray.push({ "label": "DIn 4", "value": dataArray.DIn_4, "date": date })
        newArray.push({ "label": "DIn 5", "value": dataArray.DIn_5, "date": date })
        newArray.push({ "label": "DIn 6", "value": dataArray.DIn_6, "date": date })
        newArray.push({ "label": "DIn 7", "value": dataArray.DIn_7, "date": date })
        newArray.push({ "label": "AIn 0", "value": dataArray.AIn_0, "date": date })
        newArray.push({ "label": "AIn 1", "value": dataArray.AIn_1, "date": date })
        newArray.push({ "label": "AIn 2", "value": dataArray.AIn_2, "date": date })
        newArray.push({ "label": "AIn 3", "value": dataArray.AIn_3, "date": date })
        newArray.push({ "label": "AIn 4", "value": dataArray.AIn_4, "date": date })
        newArray.push({ "label": "AIn 5", "value": dataArray.AIn_5, "date": date })
        newArray.push({ "label": "AIn 6", "value": dataArray.AIn_6, "date": date })
        newArray.push({ "label": "AIn 7", "value": dataArray.AIn_7, "date": date })
        return newArray;
    }

    function handleBackArrow(event) {
        setMainwindow("map");
    }

    function onGridReady(params) {
        setGridApi(params.api);
    }

    function formatValues(number) {
        const data = number.data;
        number = Number(String(number.value).replace(",", "."));
        number = runCalibration(data, number);
        return fancyDecimals(number);
    }

    function fancyDecimals(number) {
        let decimals = String(number).split(".")[1];
        if (decimals != undefined)
            return Number(number).toFixed(2);
        else
            return String(number);
    }

    function runCalibration(data, number) {
        number = Number(number);
        if (calibration != 0 && loadedCalibration[calibration] && !data.label.startsWith("DIn")) {
            const calibrationValues = loadedCalibration[calibration][loadedCalibration[calibration].findIndex(value => value.SensorID.replace("_", " ") === data.label)];
            if (calibrationValues) {
                calibrationValues.Ofs = isNaN(calibrationValues.Ofs) || calibrationValues.Ofs === null ? 0 : calibrationValues.Ofs;
                calibrationValues.Scl = isNaN(calibrationValues.Scl) || calibrationValues.Scl === null ? 1 : calibrationValues.Scl;
                calibrationValues.Cor = isNaN(calibrationValues.Cor) || calibrationValues.Cor === null ? 0 : calibrationValues.Cor;
                calibrationValues.Min = isNaN(calibrationValues.Min) || calibrationValues.Min === null ? 0 : calibrationValues.Min;
                calibrationValues.Max = isNaN(calibrationValues.Max) || calibrationValues.Max === null ? 10 : calibrationValues.Max;

                number += calibrationValues.Ofs; // Add offset
                number *= calibrationValues.Scl; // Multiply scale 
                number *= (calibrationValues.Max - calibrationValues.Min) / 10 // Multiply range
                number += calibrationValues.Min // Add min
                number = Math.min(Math.max(calibrationValues.Min, number), calibrationValues.Max) // Bound by min and max
                number = number < calibrationValues.Cor && number > calibrationValues.Cor*(-1) ? 0 : number // Bound corridor
            }
        }
        return number;
    }

    const columns = [
        {
            field: 'date', width: 200, headerName: 'Datum',
            valueFormatter: (params) => {
                let formatted = formatDate(params.value);
                if (formatted)
                    return formatted.replace(/-/g, ".")
                else
                    return params.value
            }
        },
        {
            field: 'label', headerName: 'Label', valueFormatter: (params) => params.value.replace("_", " "), cellRenderer: "agAnimateShowChangeCellRenderer", flex: 1,
            valueGetter: (params) => {
                if (calibration != 0 && loadedCalibration[calibration]) {
                    const calibrationValues = loadedCalibration[calibration][loadedCalibration[calibration].findIndex(value => value.SensorID.replace("_", " ") === params.data.label)];
                    if (calibrationValues) {
                        const calibrationName = calibrationValues.SensorName;
                        if (calibrationName)
                            return calibrationName;
                    }
                }
                return params.data.label;
            }
        },
        {
            field: 'value', headerName: 'Wert', cellClass: "number-cell", cellRenderer: "agAnimateShowChangeCellRenderer",
            flex: 1, valueFormatter: (params) => formatValues(params)
        },
        {
            field: 'difference', headerName: 'Veränderung', cellClass: "number-cell", flex: 1, valueFormatter: (params) => fancyDecimals(params.value),
            valueGetter: (params) => {
                const oldValue = runCalibration(params.data, params.data.oldValue);
                const newValue = runCalibration(params.data, params.data.value);

                if (!oldValue || !newValue)
                    return 0;
                else if (oldValue > newValue) {
                    return -Math.abs(oldValue - newValue);
                }
                else if (oldValue < newValue) {
                    return +Math.abs(newValue - oldValue);
                }
                else {
                    return 0;
                }
            },
            cellStyle: params => params.node.data.oldValue > params.node.data.value ? { color: 'red' } : params.node.data.oldValue < params.node.data.value ? { color: 'green' } : { color: "black" }
        },
        {
            field: "oldValue", hide: true
        }
    ];

    let calibrationsList = [];
    Object.keys(loadedCalibration).forEach(key => {
        calibrationsList.push(key);
    });
    const calibrationsMenu = (
        calibrationsList.map((value, index) => (<MenuItem key={value} value={value}>{value}</MenuItem>))
    );

    const rows = [];
    for (var i = 0; i < liveData.length; i++) {
        try {
            if (liveData[i] !== undefined) {
                const label = liveData[i].label;
                const value = liveData[i].value;
                const date = liveData[i].date;

                if (label && value !== null && date && (calibration === 0 || loadedCalibration && loadedCalibration[calibration].findIndex(value => value.SensorID === label.replace(" ", "_")) !== -1))
                    rows.push({ "date": date, "label": label, "value": value, "difference": 0, "oldValue": value });
            }
        } catch (err) {
            console.log("Error loading data");
        }
    }

    return (
        <Box borderRight={1}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar}>
                    <Grid item style={{ paddingLeft: 17 }} >
                        <Typography noWrap variant="h4">Live Daten</Typography>
                    </Grid>
                    <Grid item style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                        <FormControl style={{ minWidth: "140px", margin: "4px" }} size={"small"}>
                            <InputLabel id="calibration-label">Kalibrierung</InputLabel>
                            <Select
                                autoWidth
                                id="calibration-select"
                                label="Kalibrierung"
                                value={calibration}
                                onChange={(event) => setCalibration(event.target.value)}
                            >
                                <MenuItem value={0}>Keine</MenuItem>
                                {calibrationsMenu}
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleBackArrow}
                            style={{ margin: 5 }}
                        >
                            <Close />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container xs>
                    <div className="ag-theme-material" style={{ height: '100%', width: '100%' }}>
                        <AgGridReact localeText={AG_GRID_LOCALE_DE} reactUi={true} animateRows={true} defaultColDef={{ sortable: false, resizable: false }} suppressRowClickSelection={true} rowData={rows} columnDefs={columns} rowSelection="none" onGridReady={onGridReady}>
                        </AgGridReact>
                        <UseTimer />
                    </div>
                </Grid>
            </Grid>
        </Box >
    );
}