import "./ImagePage.css";
import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { MainWindowContext } from "../MainWindowContext.js";
import { Context } from '../Context.js';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography, Box, Grid, Button, Divider } from '@mui/material';
import { ArrowBack, ArrowForward, RefreshOutlined, Close } from '@mui/icons-material';
import ImageGallery from 'react-image-gallery';
import { useKeycloak } from '@react-keycloak/web'
import { apiaddress } from "../const.js"

export default function Imagepage(props) {
    const [initialized, setInitialized] = useState(false);
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [sidebarstatus, setSidebarstatus] = useContext(Context);
    const { keycloak, keycloakInitialized } = useKeycloak();
    const [value, setValue] = useState(props.value);
    const [loadedImages, setLoadedImages] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [imagesListIndex, setImagesListIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(0);
    const [refreshColor, setRefreshColor] = useState("primary");
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const imageCount = 10;

    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        toolbar: {
            height: '60px',
            minHeight: "60px",
            minWidth: "500px",
            backgroundColor: theme.palette.primary.light,
        },
        maingrid: {
            height: "100vh",
            width: '100%',
            backgroundColor: '#f4f9fc'
        },
        datagrid: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }));
    const classes = useStyles();

    if (initialized === false) {
        setInitialized(true);
        loadImages();
    }

    function UseTimer() {
        useInterval(() => {
            pollUpdate();
        }, 2000);
        return null;
    };

    useEffect(() => {
        const maxPage = Math.ceil(imagesList.length / imageCount);
        setMaxPage(maxPage);

        let newPage = currentPage;
        if (maxPage <= 1) {
            newPage = maxPage;
        }
        else {
            if (lastIndex > imagesListIndex)
                newPage = (currentPage - 1);
            else if (lastIndex < imagesListIndex)
                newPage = (currentPage + 1);
        }
        setCurrentPage(newPage);
        setLastIndex(imagesListIndex);
    }, [imagesList, imagesListIndex])

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    async function pollUpdate() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/equipments/imageslist/`, { headers: { Authorization: keycloak.token }, params: { id: value[sidebarstatus].EquipmentID } })
            .then(async response => {
                if (response.data) {
                    const list = response.data[0].Imageslist
                    if (imagesList.length != list.length)
                        setRefreshColor("success");
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            });
    };

    async function loadImages(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/equipments/imageslist/`, { headers: { Authorization: keycloak.token }, params: { id: value[sidebarstatus].EquipmentID } })
            .then(async response => {
                if (response.data) {
                    const list = response.data[0].Imageslist
                    setImagesList(list);
                    setImagesListIndex(0);
                    setLoadedImages(await initImages(0, list));
                    setRefreshColor("primary");
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            });
    }

    async function downloadImage(file) {
        await keycloak.updateToken(5);
        return new Promise((resolve, reject) => {
            if (!file)
                reject();

            axios.post(`${apiaddress}/equipments/images/${value[sidebarstatus].EquipmentID}`, [file], { headers: { Authorization: keycloak.token } })
                .then(response => {
                    resolve(response.data[0]);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status === 401)
                            setMainwindow("login");
                        else if (err.response.status === 402)
                            setMainwindow("login");
                        else
                            reject();
                    }
                })
        })
    }

    function handleBackArrow(event) {
        setMainwindow("map");
    }

    async function indexForward(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let newIndex = imagesListIndex + imageCount;
        if (imagesList.length - imageCount <= newIndex)
            newIndex = Math.max(0, imagesList.length - imageCount);
        if (imagesListIndex != newIndex) {
            setImagesListIndex(newIndex);
            setLoadedImages(await initImages(newIndex));
        }
    }

    async function indexBack(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let newIndex = Math.max(0, imagesListIndex - imageCount);
        if (imagesListIndex != newIndex) {
            setImagesListIndex(newIndex);
            setLoadedImages(await initImages(newIndex));
        }
    }

    async function initImages(newIndex = 0, list = []) {
        if (imagesList.length > 0 && list.length === 0)
            list = imagesList;
        let loadedImages = [];
        let length = list.length < imageCount ? list.length : imageCount;
        for (let index = newIndex; index < length + newIndex; index++) {
            await downloadImage(list[index])
                .then((img) => loadedImages.push(img))
                .catch((err) => {
                    console.log(err);
                });
        }
        let array = [];
        loadedImages.forEach(image => {
            array.push({ original: image, thumbnail: image });
        });
        if (newIndex === 0)
            setCurrentPage(1);
        return array;
    }

    const images = loadedImages;

    var ImageComponent = "";
    if (images.length > 0)
        ImageComponent = (
            <ImageGallery items={images} showPlayButton={false} />
        );

    return (
        <Box borderRight={1}>
            <Grid container direction="column" alignItems="center" className={classes.maingrid} wrap="nowrap">
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar}>
                    <Grid style={{ paddingLeft: 17 }} >
                        <Typography variant="h4" >Kameras</Typography>
                    </Grid>
                    <Grid item xs container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={indexBack}
                            style={{ margin: 5 }}>
                            <ArrowBack />
                        </Button>
                        <Typography variant="button" style={{ fontWeight: 700, fontSize: 20, justifyContent: "center", alignItems: "center", paddingTop: "6px", paddingLeft: "2px", paddingRight: "2px" }}>{currentPage}/{maxPage}</Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={indexForward}
                            style={{ margin: 5 }}>
                            <ArrowForward />
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color={refreshColor}
                            onClick={loadImages}
                            style={{ margin: 5 }}>
                            <RefreshOutlined />
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleBackArrow}
                            style={{ margin: 5 }}>
                            <Close />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs>
                    {ImageComponent}
                </Grid>
            </Grid>
            <UseTimer />
        </Box>
    );
}