import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import { makeStyles, useTheme } from '@mui/styles';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { VerticalAlignTop, ElectricCar, Description, Image, TrackChanges, Logout, Lock, LocationOn, ArrowBack, CameraAlt, DirectionsCar, Router } from '@mui/icons-material';
import { Divider, Button, ListItem, Typography, Grid, ListItemText, ListItemIcon } from '@mui/material';
import { formatPrettyDate } from './tools.js';
import PlateIcon from './img/plate.png';
import { Context } from "./Context.js";
import { MainWindowContext } from "./MainWindowContext.js";
import DownloadPage from './components/DownloadPage.js';
import Livepage from './components/LivePage';
import Imagepage from './components/ImagePage';
import LoadingSvg from './assets/puff.svg';
import { useKeycloak } from '@react-keycloak/web'
import { apiaddress, version } from "./const.js"

const Color = require('color');
const coordinatesFetchLastHours = 168;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100%',
    },
    eins: {
        height: '100%'
    },
    zwei: {
        height: '100%'
    },
    signout: {
        margin: 15
    },
    sidebar: {
        whiteSpace: 'normal'
    },
    absolute: {
        marginRight: 10,
    }
}));

function App(props) {
    const { keycloak, keycloakInitialized } = useKeycloak();
    const theme = useTheme();
    return keycloak.authenticated ? <Dashboard {...props} /> : <img alt='' src={LoadingSvg} style={{ backgroundColor: theme.palette.primary.main, height: '100vh', width: '100%' }} />;
}

function Dashboard(props) {
    const [units, setUnits] = useState([]);
    const [zoomBool, setZoomBool] = useState(false);
    const [sidebarstatus, setSidebarstatus] = useState("list");
    const [mainwindow, setMainwindow] = useState("map");
    const { keycloak, keycloakInitialized } = useKeycloak();
    const classes = useStyles();

    useEffect(() => {
        if (mainwindow != "map")
            return;
        if (sidebarstatus >= 0)
            selectEquipment(units[sidebarstatus]);
        else if (sidebarstatus === "list")
            selectEquipments();
    }, [mainwindow, sidebarstatus]);

    useEffect(() => {
        axios.put(`${apiaddress}/register`, "", { headers: { Authorization: keycloak.token } })
            .catch(err => {
                alert("Error occurred while registering user to API");
                console.log(err);
            });
    }, [])

    function handleLogout(event) {
        keycloak.logout();
    }

    function HandleBackArrow(event) {
        setMainwindow("map");
        setSidebarstatus("list");
    }

    function handleDownloadpage(event) {
        setMainwindow("download");
    }

    function handleLivePage(event) {
        setMainwindow("live");
    }

    function handleImagePage(event) {
        setMainwindow("image");
    }

    function getAddress(latitude, longitude) {
        return new Promise(async (resolve, reject) => {
            fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?language=de&types=address&access_token=pk.eyJ1IjoiZGVyemVyc3RhbXBmZXIiLCJhIjoiY2tqN2pxMXR1MG8xazJwcnU4enZia3hqNCJ9.I4mAP-xy-s0VoI5CwgXayA`)
                .then(response => {
                    response.json()
                        .then(data => {
                            if (data.features.length == 0) {
                                resolve(undefined);
                                return;
                            }
                            let raw = data.features[0];
                            let country = "Unbekannt";
                            let city = "Unbekannt";
                            let postal = "Unbekannt";
                            raw.context.map((value, index) => {
                                if (value.id.startsWith("postcode"))
                                    postal = value.text_de;
                                else if (value.id.startsWith("place"))
                                    city = value.text_de;
                                else if (value.id.startsWith("country"))
                                    country = value.text_de;
                            });
                            let address = raw.text_de == undefined ? "Unbekannt" : raw.text_de
                            let addressNumber = raw.address == undefined ? "" : raw.address;
                            let formattedAddress = `${address} ${addressNumber}\n${postal} ${city}\n${country}`;
                            resolve(formattedAddress);
                        })
                        .catch(error => {
                            resolve(undefined);
                        });
                })
                .catch(error => {
                    resolve(undefined);
                })
        });
    }

    async function selectEquipments() {
        await keycloak.updateToken(5);
        axios.get(`${apiaddress}/equipments/coordinates?limit=1`, { headers: { Authorization: keycloak.token } })
            .then(response => {
                console.log(response)
                setZoomBool(true);
                if (typeof response.data != "string") {
                    response.data.sort((a, b) => a.Name.localeCompare(b.Name));
                    setUnits(response.data);
                }
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401)
                        handleLogout();
                    else if (err.response.status === 402)
                        handleLogout();
                }
            });
    }

    async function selectEquipment(sEquipment) {
        await keycloak.updateToken(5);
        axios.get(`${apiaddress}/equipments/coordinates?id=${sEquipment.EquipmentID}&since=${coordinatesFetchLastHours}&limit=100`, { headers: { Authorization: keycloak.token } })
            .then(async response => {
                let newUnits = [];
                if (typeof response.data != "string") {
                    let tUnits = units;
                    response.data.map((data, dataIndex) => {
                        newUnits = tUnits.map((eq, eqIndex) => {
                            if (eq.EquipmentID === data.EquipmentID)
                                eq = data;
                            return eq;
                        });
                    });
                }
                if (newUnits.length === 0)
                    newUnits = units;

                newUnits.sort((a, b) => a.Name.localeCompare(b.Name))
                setUnits(newUnits);
                setZoomBool(true);

                getAddress(newUnits[sidebarstatus].Coordinates[0].Latitude, newUnits[sidebarstatus].Coordinates[0].Longitude)
                    .then(address => {
                        let newAddress = newUnits.map((eq, eqIndex) => {
                            return eq;
                        })
                        newAddress[sidebarstatus].place = address;
                        setUnits(newAddress)
                    })
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401)
                        handleLogout();
                    else if (err.response.status === 402)
                        handleLogout();
                }
            });
    }

    if (units.length >= 1) {
        var sidebar;
        if (sidebarstatus === "list") {
            sidebar = (
                <div>
                    {
                        units.map((value, index, array) => {
                            let icon;
                            if (value.IconStyle === "cam")
                                icon = (<CameraAlt />)
                            else if (value.IconStyle === "trailer")
                                icon = (<DirectionsCar />)
                            else
                                icon = (<Router />)

                            return (
                                <ListItem key={index} button onClick={() => setSidebarstatus(index)}>
                                    <ListItemIcon>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={value.Name} />
                                </ListItem>
                            )
                        })
                    }
                </div>
            );
        } else {
            var doorstates;
            var couplingstates;
            var numberplate;
            var battery;
            var icon;

            if (units[sidebarstatus].IconStyle === "cam")
                icon = (<CameraAlt />)
            else if (units[sidebarstatus].IconStyle === "trailer")
                icon = (<DirectionsCar />)
            else
                icon = (<Router />)
            if (units[sidebarstatus].Show_Door == true)
                doorstates = (
                    <ListItem>
                        <ListItemIcon>
                            <Lock />
                        </ListItemIcon>
                        <ListItemText primary={"Türen:"} secondary={units[sidebarstatus].Doorstates && units[sidebarstatus].Doorstates[0] !== undefined ? (units[sidebarstatus].Doorstates[0] === 0 ? "Geschlossen" : units[sidebarstatus].Doorstates[0] === 1 ? "Offen" : "Unbekannt") : "Unbekannt"} />
                    </ListItem>
                )

            if (units[sidebarstatus].Show_Coupling == true)
                couplingstates = (
                    <ListItem>
                        <ListItemIcon>
                            <VerticalAlignTop />
                        </ListItemIcon>
                        <ListItemText primary={"Anhänger:"} secondary={units[sidebarstatus].Couplingstates && units[sidebarstatus].Couplingstates[0] !== undefined ? (units[sidebarstatus].Couplingstates[0] === 0 ? "Abgekoppelt" : units[sidebarstatus].Couplingstates[0] === 1 ? "Angekoppelt" : "Unbekannt") : "Unbekannt"} />
                    </ListItem>
                )
            if (units[sidebarstatus].Plate != "" && units[sidebarstatus].Plate !== undefined)
                numberplate = (
                    <ListItem>
                        <ListItemIcon>
                            <img src={PlateIcon} />
                        </ListItemIcon>
                        <ListItemText primary={"Nummernschild:"} secondary={units[sidebarstatus].Plate} />
                    </ListItem>
                )
            if (units[sidebarstatus].Batterystates !== undefined && units[sidebarstatus].Batterystates[0] !== undefined)
                battery = (
                    <ListItem>
                        <ListItemIcon>
                            <ElectricCar />
                        </ListItemIcon>
                        <ListItemText primary={"Batterie:"} secondary={units[sidebarstatus].Batterystates[0] !== "" ? `${units[sidebarstatus].Batterystates[0]}V` : "Unbekannt"} />
                    </ListItem>
                )

            sidebar =
                <div>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: 1 }}>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.signout}
                                onClick={HandleBackArrow}
                            >
                                <ArrowBack />
                            </Button>
                        </Grid>
                        <Grid item container xs className={classes.absolute}  >
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                {units[sidebarstatus].Show_Download == true ?
                                    <Grid item>
                                        <Button type="submit" variant="contained" color="primary" onClick={handleDownloadpage}>
                                            <Description />
                                        </Button>
                                    </Grid> : ""}
                                {units[sidebarstatus].Show_Live == true ?
                                    <Grid item>
                                        <Button type="submit" variant="contained" color="primary" onClick={handleLivePage}>
                                            <TrackChanges />
                                        </Button>
                                    </Grid> : ""}
                                {units[sidebarstatus].Show_Images == true ?
                                    <Grid item>
                                        <Button type="submit" variant="contained" color="primary" onClick={handleImagePage}>
                                            <Image />
                                        </Button>
                                    </Grid> : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                    <ListItem>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={"Name:"} secondary={String(units[sidebarstatus].Name).replace("_", " ")} />
                    </ListItem>
                    {numberplate}
                    <ListItem>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={"Position:"} secondary={`${units[sidebarstatus].Coordinates[0].Lastupdate == null ? formatPrettyDate(units[sidebarstatus].Coordinates[0].Timestamp) : formatPrettyDate(units[sidebarstatus].Coordinates[0].Lastupdate)}\n${units[sidebarstatus].place == undefined ? "Fetching address..." : units[sidebarstatus].place}\n(${units[sidebarstatus].Coordinates[0].Latitude}, ${units[sidebarstatus].Coordinates[0].Longitude})`}
                        />
                    </ListItem>
                    {doorstates}
                    {couplingstates}
                    {battery}
                </div>;
        }
    }

    var mainwindowcontent;

    if (mainwindow == "map") {
        mainwindowcontent = (
            <Context.Provider value={[sidebarstatus, setSidebarstatus]}>
                <Map marker={units} selected={sidebarstatus} shouldZoom={zoomBool} />
            </Context.Provider>
        );
    } else if (mainwindow == "download") {
        mainwindowcontent = (
            <Context.Provider value={[sidebarstatus, setSidebarstatus]}>
                <MainWindowContext.Provider value={[mainwindow, setMainwindow]}>
                    <DownloadPage value={units} />
                </MainWindowContext.Provider>
            </Context.Provider>
        );
    } else if (mainwindow == "live") {
        mainwindowcontent = (
            <Context.Provider value={[sidebarstatus, setSidebarstatus]}>
                <MainWindowContext.Provider value={[mainwindow, setMainwindow]}>
                    <Livepage value={units} />
                </MainWindowContext.Provider>
            </Context.Provider>
        );
    } else if (mainwindow == "image") {
        mainwindowcontent = (
            <Context.Provider value={[sidebarstatus, setSidebarstatus]}>
                <MainWindowContext.Provider value={[mainwindow, setMainwindow]}>
                    <Imagepage value={units} />
                </MainWindowContext.Provider>
            </Context.Provider>
        );
    }

    useEffect(() => {
        setZoomBool(false);
    }, [units, mainwindow]);

    return (
        <Grid wrap='nowrap' container className={classes.root} spacing={0}>
            <Grid xs className={classes.eins} item>
                {mainwindowcontent}
            </Grid>
            <Grid wrap="nowrap" item container direction='column' className={classes.zwei} style={{ width: "400px", minWidth: "400px" }} >
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent='space-between'
                    spacing={0}
                    wrap="nowrap"
                >
                    <Grid item container justifyContent={"space-between"} sx={{ margin: 1 }} direction='row'>
                        <Grid item container xs direction={"row"} >
                            <Typography variant="h4" style={{ fontSize: "30px" }} >Flottenmanager</Typography>
                            <Typography color="primary" style={{ fontWeight: 600, fontSize: "11px" }} >{version}</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="tertiary"
                                className={classes.signout}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </Button>
                        </Grid>
                    </Grid>


                </Grid>
                <Divider />
                {sidebar}
            </Grid>
        </Grid>
    );
}

function createPolyLines(center) {
    let firstlatlng = [];
    let otherlatlngs = [];
    center[0].map((latitude, index) => {
        if (firstlatlng.length < 2) {
            firstlatlng.push([Number(latitude), Number(center[1][index])]);
        }
        if (firstlatlng.length >= 2)
            otherlatlngs.push([Number(latitude), Number(center[1][index])]);
    });

    let polyColor = "#71B4CC";
    let fadeColor = undefined;
    let fadeEach = function () {
        let fadeRatio = 0.3;
        let lines = otherlatlngs.length - 1;
        return fadeRatio / lines
    }();
    let polyWidth = 3;

    let lineLayers = [];
    lineLayers.push(L.polyline(firstlatlng, { interactive: false, weight: polyWidth, color: polyColor, fill: false }));
    otherlatlngs.map((latlng, index) => {
        if (otherlatlngs.length > index + 1) {
            let color = fadeColor == undefined ? polyColor : fadeColor;
            fadeColor = Color(color).lighten(fadeEach);

            lineLayers.push(L.polyline([latlng, otherlatlngs[index + 1]], { interactive: false, weight: polyWidth, color: fadeColor, fill: false }));
        }
    });
    return lineLayers;
}

function ChangeView({ center }) {
    const map = useMap();

    let context = useContext(Context)[0];

    if (map.lineLayers != undefined) {
        map.lineLayers.map((layer) => {
            layer.remove();
        })
    }

    if (center[0].length > 1) {
        let smallestLatitude = undefined;
        let longestLatitude = undefined;
        let smallestLongitude = undefined;
        let longestLongitude = undefined;

        center[0].map((latitude) => {
            if (latitude < smallestLatitude || smallestLatitude == undefined)
                smallestLatitude = Number(latitude);
            if (latitude > longestLatitude || longestLatitude == undefined)
                longestLatitude = Number(latitude);

        });

        center[1].map((longitude) => {
            if (longitude < smallestLongitude || smallestLongitude == undefined)
                smallestLongitude = Number(longitude);
            if (longitude > longestLongitude || longestLongitude == undefined)
                longestLongitude = Number(longitude);
        });

        if (smallestLatitude == undefined || longestLatitude == undefined || smallestLongitude == undefined || longestLongitude == undefined)
            return null;

        map.flyToBounds(L.latLngBounds(L.latLng(Number(smallestLatitude), Number(smallestLongitude)), L.latLng(Number(longestLatitude), Number(longestLongitude))), { duration: 0.45 });

        if (!isNaN(context)) {
            map.lineLayers = createPolyLines(center);
            map.lineLayers.map((layer) => map.addLayer(layer));
        }
    }
    else {
        let latitude = Number(center[0]);
        let longitude = Number(center[1]);
        map.flyTo([latitude, longitude], 10, { duration: 0.45 });
    }

    return null;
}

function handleZoom(markers, index) {
    let newZoom = [[], []];

    if (!isNaN(index) && index >= 0) {
        markers[index].Coordinates.forEach((coordinates) => {
            newZoom[0].push(coordinates.Latitude);
            newZoom[1].push(coordinates.Longitude);
        })
    }
    else if (isNaN(index)) {
        markers.map((equipment, index) => {
            newZoom[0].push(equipment.Coordinates[0].Latitude);
            newZoom[1].push(equipment.Coordinates[0].Longitude);
        });
    }

    if (newZoom.length != 0)
        return newZoom;
    else
        return [[49.202076], [8.151343]]
}

function Map({ marker, selected, shouldZoom }) {
    const [context, setContext] = useContext(Context);

    const Markers = function () {
        let markerList = [];
        if (selected == "list") {
            marker.map((value, index) => {
                markerList.push(<Marker key={index} position={[value.Coordinates[0].Latitude, value.Coordinates[0].Longitude]} eventHandlers={{
                    click: (e) => setContext(index)
                }}
                >
                    <Tooltip>{value.Name}</Tooltip>
                </Marker>
                )
            });
        }
        else if (!isNaN(selected)) {
            markerList.push(<Marker key={selected} position={[marker[selected].Coordinates[0].Latitude, marker[selected].Coordinates[0].Longitude]} eventHandlers={{
                click: (e) => setContext(selected)
            }}
            >
                <Tooltip>{marker[selected].Coordinates[0].Latitude + ", " + marker[selected].Coordinates[0].Longitude}</Tooltip>
            </Marker>
            )
        }
        return markerList;
    }();
    const GetChangeView = function () {
        var view;
        if (shouldZoom == true)
            view = <ChangeView key="ChangeView" center={handleZoom(marker, selected)} shouldZoom={shouldZoom} />;
        return view;
    }();

    return (
        <div>
            <MapContainer center={[49.202076, 8.151343]} zoom="20" scrollWheelZoom={true}>
                <TileLayer
                    url='https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGVyemVyc3RhbXBmZXIiLCJhIjoiY2tqN2pxMXR1MG8xazJwcnU4enZia3hqNCJ9.I4mAP-xy-s0VoI5CwgXayA'
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {Markers}
                {GetChangeView}
            </MapContainer>
        </div>
    );
}

export default App;
