import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import keycloak from './keycloak'
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const theme = createTheme({
  palette: {
    primary: {
      light: "#72aeff",
      main: "#307fe2",
      dark: "#0053af"
    },
    secondary: {
      light: "#4d5080",
      main: "#212853",
      dark: "#00002a"
    }, tertiary: {
      main: red["500"]
    }
  }
})

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: "login-required", pkceMethod: 'S256' }}>
    <ThemeProvider theme={theme}>
      <TokenUpdater />
      <App/>
    </ThemeProvider>
  </ReactKeycloakProvider>
);

function TokenUpdater() {
  const { keycloak, initialized } = useKeycloak();
  if (initialized)
    keycloak.onTokenExpired = updateToken;

  function updateToken() {
    keycloak.updateToken(10)
      .catch(() => { alert("Authentifizierungsfehler! Bitte neu anmelden!"); window.location.reload() });
    return;
  }
  return null;
}