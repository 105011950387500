import React, { useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer'
import { MainWindowContext } from "../MainWindowContext.js";
import { Context } from '../Context.js';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import AG_GRID_LOCALE_DE from './AG_Grid_Locale_De.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Close, GetApp } from '@mui/icons-material';
import { Button, Grid, Box, CircularProgress, Typography } from '@mui/material';
import { formatDate } from '../tools.js';
import { useKeycloak } from '@react-keycloak/web'
import { apiaddress } from "../const.js"

const fileDownload = require('js-file-download');

export default function Downloadpage(props) {
    const [mainwindow, setMainwindow] = useContext(MainWindowContext);
    const [sidebarstatus, setSidebarstatus] = useContext(Context);
    const [value, setValue] = useState(props.value);
    const [files, setFiles] = useState([]);
    const { keycloak, initialized } = useKeycloak();
    const [key, setKey] = useState();
    const [downloadState, setDownloadState] = useState(false)
    const [hotspotdownloadState, setHotspotDownloadState] = useState(false)
    const [gridApi, setGridApi] = useState();
    const [curSelect, _setCurSelect] = useState(Number(0));
    const curSelectRef = useRef(curSelect);
    const [maxSelect, setMaxSelect] = useState(Number(500));
    const [listStyle, setListStyle] = useState("reports");

    const useStyles = makeStyles((theme) => ({
        toolbar: {
            height: '60px',
            minHeight: "60px",
            minWidth: "500px",
            backgroundColor: theme.palette.primary.light,
        },
        maingrid: {
            height: '100vh',
            width: '100%',
            backgroundColor: '#f4f9fc'
        },
        datagrid: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }));
    const classes = useStyles();

    if (sidebarstatus != "list" && sidebarstatus != key && sidebarstatus != undefined) {
        setKey(sidebarstatus);
        updateReports();
    }

    async function updateReports() {
        await keycloak.updateToken(5)
        axios.get(`${apiaddress}/equipments/reportslist`, { headers: { Authorization: keycloak.token }, params: { id: value[sidebarstatus].EquipmentID } })
            .then(response => {
                response.data.forEach(equipment => {
                    if (equipment.EquipmentID == value[sidebarstatus].EquipmentID) {
                        let files = [];
                        let listStyle;
                        if (equipment.Reportslist[0] != undefined && (equipment.Reportslist[0].endsWith(".pdf") || equipment.Reportslist[0].endsWith(".csv")))
                            listStyle = "reports";
                        else if (equipment.Reportslist[0] != undefined && (equipment.Reportslist[0].endsWith(".jpg") || equipment.Reportslist[0].endsWith(".png")))
                            listStyle = "images";
                        setListStyle(listStyle);
                        if (listStyle === "reports") {
                            equipment.Reportslist.map((value) => {  // Check format validity
                                if (convertDate(value.substring(0, 16)) != "Invalid Date")
                                    files.push(value);
                            });
                            files.sort((b, a) => {
                                let dateAString = a.substring(0, 16);
                                let dateA = convertDate(dateAString);
                                let dateBString = b.substring(0, 16);
                                let dateB = convertDate(dateBString);
                                if (dateA == "Invalid Date" || dateB == "Invalid Date") {
                                    return -1;
                                }
                                else {
                                    if (dateA > dateB)
                                        return 1;
                                    else if (dateA < dateB)
                                        return -1;
                                    else
                                        return 0;
                                }
                            });
                        }
                        else if (listStyle === "images") {
                            equipment.Reportslist.map((value) => {  // Check image format validity
                                if (convertDateImages(value) != "Invalid Date")
                                    files.push(value);
                            });

                            files.sort((a, b) => {
                                let dateA = convertDateImages(a);
                                let dateB = convertDateImages(b);
                                if (dateA == "Invalid Date" || dateB == "Invalid Date") {
                                    return 0;
                                }
                                else {
                                    if (dateA > dateB)
                                        return 1;
                                    else if (dateA < dateB)
                                        return -1;
                                    else
                                        return 0;
                                }
                            });
                        }
                        setFiles(files);
                    }
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            })
    }

    function handleBackArrow(event) {
        setMainwindow("map");
    }

    async function handleDownload(event) {
        event.preventDefault();
        event.stopPropagation();

        let selectedFileNames = [];
        let nodes = gridApi.getSelectedNodes();
        nodes.forEach(node => {
            selectedFileNames.push(node.data.file);
        });
        if (selectedFileNames.length == 0)
            return;


        setDownloadState(true);
        await keycloak.updateToken(5);
        axios.post(`${apiaddress}/equipments/reports/${value[sidebarstatus].EquipmentID}`, selectedFileNames, { headers: { Authorization: keycloak.token } })
            .then(response => {
                const type = response.data[1];
                const buffer = Buffer.from(response.data[0]);
                if (type == "application/zip") {
                    if (listStyle === "reports")
                        fileDownload(buffer, "Protokolle.zip");
                    else if (listStyle === "images")
                        fileDownload(buffer, "Aufnahmen.zip");
                }
                else {
                    fileDownload(buffer, selectedFileNames[0]);
                }
                setDownloadState(false);
            })
            .catch((err) => {
                console.log(err);
                setDownloadState(false);
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            })
    }

    async function handleHotspotDownload(event) {
        event.preventDefault();
        event.stopPropagation();

        let selectedFileNames = [];
        let nodes = gridApi.getSelectedNodes();
        nodes.forEach(node => {
            selectedFileNames.push(node.data.file);
        });
        if (selectedFileNames.length == 0)
            return;

        setHotspotDownloadState(true);
        await keycloak.updateToken(5);
        axios.post(`${apiaddress}/hotspot`, selectedFileNames, { headers: { Authorization: keycloak.token } })
            .then(response => {
                const buffer = Buffer.from(response.data[0]);
                fileDownload(buffer, response.data[2]);
                setHotspotDownloadState(false);
            })
            .catch((err) => {
                setHotspotDownloadState(false);
                if (err.response) {
                    if (err.response.status === 401)
                        setMainwindow("login");
                    else if (err.response.status === 402)
                        setMainwindow("login");
                }
            })
    }

    function convertDate(dateString) {
        if (dateString == new Date(dateString))
            return dateString;
        dateString = String(dateString).replace("_", ".");
        dateString = String(dateString).replace(" ", ".");
        let newDate = new Date(dateString.split(".")[0], Number(dateString.split(".")[1]) - 1, dateString.split(".")[2], dateString.split(".")[3], dateString.split(".")[4]);
        return newDate;
    }

    function convertDateImages(dateString) {
        if (dateString == new Date(dateString))
            return dateString;
        let newDate = new Date("20" + dateString.slice(1, 3), Number(dateString.slice(3, 5)) - 1, dateString.slice(5, 7), dateString.slice(7, 9), dateString.slice(9, 11), dateString.slice(11, 13));
        return newDate;
    }

    const setCurSelect = x => {
        curSelectRef.current = x;
        _setCurSelect(x);
    };

    function onGridReady(params) {
        setGridApi(params.api);
    }

    function onSelectChanged(params) {
        let nodes = params.api.getSelectedNodes();
        setCurSelect(nodes.length);

        if (nodes.length > maxSelect) {
            let count = nodes.length - maxSelect;
            for (let i = 0; i < count; i++)
                nodes[i].setSelected(false);
        }
    }

    let columns = [];
    if (listStyle === "reports") {
        columns = [
            {
                checkboxSelection: true, headerCheckboxSelection: true,
                suppressSizeToFit: true, width: 200,
                field: 'date', headerName: 'Datum', filter: "agDateColumnFilter",
                filterParams: {
                    comparator: (selectedDate, dateString) => {
                        let convertedDate = convertDate(dateString).setHours(0, 0, 0, 0);
                        if (convertedDate > selectedDate)
                            return 1;
                        else if (convertedDate < selectedDate)
                            return -1;
                        return 0
                    }
                },
                valueFormatter: (value) => {
                    let formatted = formatDate(convertDate(value.value));
                    if (formatted)
                        return formatted.replace(/-/g, ".").slice(0, -3)
                    else
                        return value.value
                }
            },
            { field: 'client', headerName: 'Kunde', filter: "agTextColumnFilter", flex: 2 },
            { field: 'nr', headerName: 'Protokollnummer', filter: "agTextColumnFilter", width: 180 },
            { field: 'file', headerName: 'Dateiname', filter: "agTextColumnFilter", flex: 4 }
        ];
    }
    else if (listStyle === "images") {
        columns = [
            {
                checkboxSelection: true, headerCheckboxSelection: true,
                suppressSizeToFit: true, width: 200,
                field: 'date', headerName: 'Datum', filter: "agDateColumnFilter",
                filterParams: {
                    comparator: (selectedDate, dateString) => {
                        let convertedDate = convertDateImages(dateString).setHours(0, 0, 0, 0);
                        if (convertedDate > selectedDate)
                            return 1;
                        else if (convertedDate < selectedDate)
                            return -1;
                        return 0
                    }
                },
                valueFormatter: (value) => {
                    let formatted = formatDate(convertDateImages(value.value));
                    if (formatted)
                        return formatted.replace(/-/g, ".").slice(0, -3)
                    else
                        return value.value
                }
            },
            { field: 'file', headerName: 'Dateiname', filter: "agTextColumnFilter", flex: 4 }
        ]
    }

    const rows = [];

    files.map((value, index, array) => {
        if (listStyle === "reports") {
            if (value !== undefined) {
                try {
                    let id = index;
                    let date = value.substring(0, 16);
                    let client = value.split("_")[3];
                    let nr = value.split("_")[4].split(".")[0];
                    if (id != undefined && date != undefined && client != undefined && nr != undefined)
                        rows.push({ 'id': id, 'date': date, 'client': client, 'nr': nr, 'file': value });
                } catch (error) {
                }
            }
        }
        else if (listStyle === "images") {
            if (value !== undefined) {
                try {
                    let id = index;
                    let date = value;
                    if (id != undefined && date != undefined)
                        rows.push({ 'id': id, 'date': date, 'file': value });
                } catch (error) {
                }
            }
        }
    });

    return (
        <Box borderRight={1}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" className={classes.maingrid}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.toolbar} >
                    <Grid style={{ paddingLeft: 17 }} >
                        <Typography variant="h4">Protokolle</Typography>
                    </Grid>
                    <Grid xs item container style={{ justifyContent: "flex-end", paddingRight: 15 }}>
                    {value[sidebarstatus].Show_Hotspot == true ?
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={downloadState}
                            onClick={handleHotspotDownload}
                            style={{ margin: 5 }}
                        >
                            {hotspotdownloadState ? (<CircularProgress size={26} />) : (<Typography variant="button" style={{ fontWeight: 700 }}>HS Analyse</Typography>)}
                        </Button> : ""}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={downloadState}
                            onClick={handleDownload}
                            style={{ margin: 5 }}
                        >
                            {downloadState ? (<CircularProgress size={26} />) : (<Typography variant="button" style={{ fontWeight: 700 }}>{curSelect}/{maxSelect}</Typography>)}
                            {downloadState ? ("") : (<GetApp />)}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleBackArrow}
                            style={{ margin: 5 }}>
                            <Close />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container xs>
                    <div className="ag-theme-material" style={{ height: '100%', width: '100%' }} >
                        <AgGridReact style={{ '.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after': { color: "red" } }} localeText={AG_GRID_LOCALE_DE} reactUi={true} defaultColDef={{ sortable: true, resizable: true }} rowData={rows} columnDefs={columns} rowSelection="multiple" pagination={true} paginationPageSize={50} onSelectionChanged={onSelectChanged} onGridReady={onGridReady} >
                        </AgGridReact>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}